body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  z-index: 9999;
  opacity: 0.7;
}

.text-one-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.file-upload-boxes {
  display: inline-flex;
  width: 100%;
}
.swal2-title {
  display: block !important;
}
/* body .Loader .Loader__background {
  position: fixed !important;
  z-index: 999999 !important;
}
body .Loader {
  position: unset !important;
}
body .Loader, .Loader__content {
  height: auto !important;
}
.with-auth .Loader, .Loader__content{
  height: 100% !important;
} */
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: #689f38 !important;
}
body .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #689f38 !important;
  background-color: #689f38 !important;
}
body .p-treetable .p-treetable-thead > tr > th {
  border: 5px solid #ffffff !important;
  background-color: #f4f4f4 !important;
  padding: 0.857em 0.857em !important;
}
body .p-treetable .p-treetable-tbody > tr > td {
  border: 5px solid #ffffff !important;
  padding: 0.857em 0.857em !important;
  word-wrap: break-word !important;
}
body .p-treetable .p-treetable-tbody > tr:focus > td:first-child {
  box-shadow: none !important;
}
body .p-treetable .p-treetable-tbody > tr:focus > td {
  box-shadow: none !important;
}

body .p-treetable .p-treetable-tbody > tr:hover > td {
  background-color: #edeeef !important;
  color: #000 !important;
}
body a:hover {
  color: #689f38 !important;
}
:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
body .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #848484 !important;
}
body .p-treetable .p-sortable-column.p-highlight {
  color: #333333 !important;
}

button:focus {
  outline: 0px auto -webkit-focus-ring-color !important;
}
html, body, #root {
  height: 100%;
  font-family: Open Sans,sans-serif !important;
}
.Loader, .Loader__content {
  height: 100%;
  z-index: 1200;
}
.swal2-container {
  z-index: 9999 !important;
}
.p-datatable .p-datatable-thead > tr > th, .p-datatable .p-datatable-tbody > tr > td {
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; */
  font-family: Open Sans,sans-serif !important;
}
.MuiInput-underline:after {
  border-bottom: 0px !important;
}
.MuiInput-root {
  border-radius: 4px !important;
}
.form-control {
  border: 1px solid rgba(0,0,0,0.32) !important;
}
.styleclassyellow {
  background-color:  #cccc15 !important;
  color: #fff !important;
}
.styleclassgreen {
  background-color: #167a14 !important;
  color: #fff !important;
}
.styleclassred {
  background-color: #e01613 !important;
  color: #fff !important;
}
.internet-error {
  /* height: 60px; */
  background: #ff8100;
  /* margin-top: 0;
  font-size: 20px; */
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
  text-align: center;
}

.internet-error p {
  font-size: 25px;
  line-height: 60px;
  color: #fff;
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.errorvalidation {
  color: red;
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: rgba(117, 116, 116, 0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: #ccc
}

::-moz-selection { /* Code for Firefox */
  color: #fff;
  background: #999;
}

::selection {
  color: #fff;
  background: #999;
}
.order-csv-cal .p-calendar, .order-csv-cal .p-calendar input {
  width: 100%;
}