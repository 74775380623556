
.admin_offering_content{
    margin: auto;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    padding: 1rem;
    .offering_head {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 30px;
        color: #8DC73F;
        font-weight: 600;
    }
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    .total-offering-state-offering {
        font-size: 26px;
        font-weight: 600;
        color: #0a5074;
        margin-bottom: 30px;
    }
    .user_stat_detail_box {
        padding: 2rem;
        border: 1px solid #ddd;
        border-radius: 10px;
        background: #fff;
        width: 500px;
        .user_stat_detail {
            margin-top: 15px;
            .user_stat_detail_name {
                font-size: 18px;
            }
            .user_stat_detail_value {
                font-size: 18px;
                span {
                    padding-left: 10px;
                    font-size: 18px;
                    color: #000;
                    display: inline;
                }
            }
        }
    }
    .offeringlist-stats-header {
        display: flex;
        float: right;
        padding: 10px 0px;
        .admin-offering-multiselectbox {
            max-width: 250px;
            min-width: 200px;
        }
    }
    .offering-detail-amountbox {
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 4px;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .noOfInvestor {
        text-align: center;
        margin-left: 38px;
        margin-top: 5px;
        font-size: 18px;
        color: #0A5074;
    }
    .Headingname {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 24px;
        color: #8DC73F;
        font-weight: 600;
        // margin-left: 30px;
        // margin-top: -10px;
    }
    .message-filters {
        display: flex;
        .filterbyContent {
            padding-top: 7px;
            font-weight: bold !important;
            color: #245776;
            font-size: 18px;
        }
        .selectField {
            border: 2px solid #8DC73F !important;
            width: 150px;
            margin-left: 20px;
            margin-right: 20px;
            outline: none;
            outline-color: red !important;
        }
        button {
            margin-left: 20px;
        }
    }
    .config-table {
        .rgt-wrapper {
            height: calc(100vh - 220px);    
        }
    }
    .message-system-setting-table {
        .rgt-wrapper {
            height: calc(100vh - 250px);    
        }
    }
    .brokerdealer-system-setting-table {
        .rgt-wrapper {
            height: calc(100vh - 270px);    
        }
    }
    .admin-brokerdealer-offerings-table, .admin-underwriter-offerings-table {
        .rgt-wrapper {
            height: calc(100vh - 275px);    
        }
    }
    .brokerdealer-admin-table, .underwriter-admin-table {
        .rgt-wrapper {
            height: calc(100vh - 210px);    
        }
    }
    .underwriter-list-table {
        .rgt-wrapper {
            height: calc(100vh - 270px);    
        }
    }
    .config-table, .message-system-setting-table, .brokerdealer-system-setting-table, .admin-brokerdealer-offerings-table, .brokerdealer-admin-table, .underwriter-list-table, .underwriter-admin-table, .admin-underwriter-offerings-table {
        .rgt-cell-header-inner {
            font-weight: bold;
        }
    }
}
.modal-body-bizinsight{
    font-style: normal !important;
    font-weight: bold !important;
    line-height: 20px !important;
    font-size: 16px !important;
    color: #0A5074;
    cursor: pointer;

    .modal-body-bizinsight-title {
        text-align: center;
        font-size: 24px;
    }
    .modal-body-bizinsight-lists {
        padding: 10px;
        border: 1px solid #ddd;
        .modal-body-bizinsight-lists-email-box{
            display: contents;
            .modal-body-bizinsight-lists-email-plus{
                margin-right: 10px;
                font-size: 24px;
            }
        }
        .modal-body-bizinsight-lists-dates_items {
            margin-left: 30px;
            margin-top: 10px;
            .modal-body-bizinsight-lists-dates_item {
                margin-bottom: 5px;
            }
        }
        .modal-body-bizinsight-date-list{
            margin-top: 10px;
            margin-left: 25px;
        }
    }
}