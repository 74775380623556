
.stats_offering_content{
    margin: auto;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    padding: 1rem;
    .revenuetable {
        .rgt-wrapper {
            height: calc(100vh - 350px);    
        }
        .rgt-cell-header-inner {
            font-weight: bold;
        }
    }
    .statsofferingtable {
        .rgt-wrapper {
            height: calc(100vh - 200px);    
        }
        .rgt-cell-header-inner {
            font-weight: bold;
        }
    }
    .offering_head {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 30px;
        color: #8DC73F;
        font-weight: 600;
    }
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    .total-offering-state-offering {
        font-size: 26px;
        font-weight: 600;
        color: #0a5074;
        margin-bottom: 30px;
    }
    .offeringlist-stats-header {
        display: flex;
        float: right;
        padding: 10px 0px;
        .admin-offering-multiselectbox {
            max-width: 250px;
            min-width: 200px;
        }
        .offering-status-select {
            .MuiOutlinedInput-input {
                padding: 10.5px 14px;
            }
        }
    }
    .offering-detail-amountbox {
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 4px;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .noOfInvestor {
        text-align: center;
        margin-left: 38px;
        margin-top: 5px;
        font-size: 18px;
        color: #0A5074;
    }
    .Headingname {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 24px;
        color: #8DC73F;
        font-weight: 600;
        // margin-left: 30px;
        // margin-top: -10px;
    }
    .user-stats-box {
        padding: 16px;
        font-weight: bold;
    }
    .active-user-stats {
        cursor: pointer;
        color: #689F38;
    }
    .offering-stats-box {
        padding: 16px;
        cursor: pointer;
    }
    .offering-stats-box-icon-active {
        width: 60px;
        height: 60px;
        background: #689f38;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        border-radius: 100px;
    }
    .offering-stats-box-icon-effective {
        width: 60px;
        height: 60px;
        background: #2fcee4;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        border-radius: 100px;
        
    }
    .offering-state-detail {
        border: 1px solid #bfbfbf;
        padding: 10px;
        margin-bottom: 16px;
        .offering-state-detail-row {
            margin-bottom: 10px;
        }
    }
    .offering-stats-box-icon-cancelled {
        width: 60px;
        height: 60px;
        background: #f1b44f;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        border-radius: 100px;
        
    }
    .offering-stats-box-icon-closed {
        width: 60px;
        height: 60px;
        background: #fe6e50;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        border-radius: 100px;
    }
}
.modal-body-bizinsight{
    font-style: normal !important;
    font-weight: bold !important;
    line-height: 20px !important;
    font-size: 16px !important;
    color: #0A5074;
    cursor: pointer;

    .modal-body-bizinsight-title {
        text-align: center;
        font-size: 24px;
    }
    .modal-body-bizinsight-lists {
        padding: 10px;
        border: 1px solid #ddd;
        .modal-body-bizinsight-lists-email-box{
            display: contents;
            .modal-body-bizinsight-lists-email-plus{
                margin-right: 10px;
                font-size: 24px;
            }
        }
        .modal-body-bizinsight-lists-dates_items {
            margin-left: 30px;
            margin-top: 10px;
            .modal-body-bizinsight-lists-dates_item {
                margin-bottom: 5px;
            }
        }
        .modal-body-bizinsight-date-list{
            margin-top: 10px;
            margin-left: 25px;
        }
    }
}

.user_stat_detail_box {
    // padding: 2rem;
    // border: 1px solid #ddd;
    // border-radius: 10px;
    // background: #fff;
    // width: 500px;
    .user_stat_detail {
        margin-top: 15px;
        .user_stat_detail_name {
            font-size: 18px;
        }
        .user_stat_detail_value {
            font-size: 18px;
            span {
                padding-left: 10px;
                font-size: 18px;
                color: #000;
                display: inline;
            }
        }
    }
}
.follower-list-table {
    .rgt-wrapper {
        height: calc(100vh - 300px);    
    }
    .rgt-cell-header-inner {
        font-weight: bold;
    }
}