
.issuers_section_content{
    margin: auto;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    padding: 1rem;
    .table-fixed > tbody > tr > td, .table-fixed > thead > tr > th {
        vertical-align: middle;
        word-break: break-word;
    }
    .table-fixed > tbody > tr > td {
        background-color: #ffffff;
        .MuiButton-root {
            min-width: 56px;
        }
    }
    .table-fixed {
        border-collapse:separate; 
        border-spacing: 0 0.35em;
      }
    .Headingname {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 20px;
        color: #8DC73F;
    }
    .offering_head {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 30px;
        color: #8DC73F;
        font-weight: 600;
    }
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    .activity-customers {
        font-weight: 600;
        color: #245776;
        font-size: 16px;
        line-height: 24px;
        display: inline-block;
        padding: 5px 0px;
    }
    .totalCustomers {
        font-weight: 700;
        color: #245776;
        font-size: 18px;
        line-height: 24px;
        display: inline-block;
        padding: 10px;
    }
    .customer-filters {
        display: flex;
        .filterbyContent {
            padding-top: 7px;
            font-weight: bold !important;
            color: #245776;
            font-size: 18px;
        }
        .selectField {
            border: 2px solid #8DC73F !important;
            width: 150px;
            margin-left: 20px;
            margin-right: 20px;
            outline: none;
            outline-color: red !important;
        }
        button {
            margin-left: 20px;
        }
    }
    .orders-body-content {
        height: calc(100vh - 272px);
        overflow: hidden;
        overflow-y: auto;
    }
    .broker-customers-body-content {
        height: 100%;
    }
    .close-icon-for-search {
        top: 0px !important;
    }
    .rgt-wrapper {
        height: calc(100vh - 350px);    
    }
    .rgt-cell-header-inner {
        font-weight: bold;
    }
}
.activity-list-table {
    .rgt-wrapper {
        height: calc(100vh - 350px);    
    }
    .rgt-cell-header-inner {
        font-weight: bold;
    }
}
.customer-orderinfo-table {
    .rgt-wrapper {
        height: calc(100vh - 350px);    
    }
    .rgt-cell-header-inner {
        font-weight: bold;
    }
}