.create_offering_content_wrapper {
    overflow-y: auto;
    background: #fff;
    padding-top: 10px;
    height: 100%;
    .editprofile_content{
      margin: auto;
  }
  .createprofile_heading{
    text-align: center;
    height: 50px;
    font-size: 27px;
    color: #083952 !important;
    margin-bottom: 15px;
    position: relative;
  }
  .createprofile_back_icon {
    width: 100%;
    max-width: 35px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
  .createprofile_go_back{
    color: #888888;
    position: absolute;
    left: 31px;
    top: 49%;
    font-size: 16px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  @media (max-width: 575px) {
    .createprofile_back_icon,
    .createprofile_go_back {
        position: static;
        transform: initial;
        display: inline-block;
        vertical-align: middle;
    }
    .createprofile_back_icon_text {
        text-align: left;
        margin-top: 10px;
    }
    .createprofile_heading {
        line-height: 1;
        height: auto;
    }
  }
  
  .update_profile .btn{
      color: #fff;
      background-color: #5fcc40 !important;
      border-color: #5fcc40 !important;
      width: 100%;
      margin-top: 10px;
      font-size: 18px;
  }
  .btn-primary:focus{
      box-shadow: none !important;
      outline: none;
  }
  .user_details .form-control:focus{
      box-shadow: none !important;
  }
  .edit_profile_form_fields_wrapper {
      background: #fff;
      padding: 15px;
      border-radius: 4px;
  }
  .edit_profile_form_fields_wrapper .form-group {
      margin-bottom: 1rem !important;
  }
  .edit_profile_form_fields_wrapper .form-group,
  .edit_profile_form_fields_wrapper button {
      margin-top: 1rem;
  }
  .edit_profile_form_fields_wrapper .form-control {
      border: 1px solid lightgray;
  }
  
  
  .advisor-checkbox {
      position: absolute;
      opacity: 0;
      z-index: 999;
    }
    .advisor-checkbox + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      margin-bottom: 0px;
      margin-top: 10px;
    }
    .advisor-checkbox + label:before {
      content: '';
      /* margin-right: 10px; */
      /* margin-top: 4px !important; */
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      border: 1px solid #a6a6a6;
      background-color: #ffffff;
      border-radius: 4px;
      margin-right: 20px;
    }
    .advisor-checkbox:hover + label:before {
      /* background: #689F38; */
      border: 1px solid #000;
    }
    // .advisor-checkbox:focus + label:before {
    //   /* box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12); */
    // }
    .advisor-checkbox:checked + label:before {
      background: #689F38;
    }
    .advisor-checkbox:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
    .advisor-checkbox:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
    .advisor-checkbox:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 11px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }
    .error-message{
        color: red;
    }
    .brochure_url_radiobox {
      display: flex;
      margin-top: 10px;
    }
    .brochure_url_radiobox_btn {
      margin-right: 20px;
    }
    .update-label{
      font-size: 14px;
      line-height: 18px;
      color: #678899;
    }
    .create_offering{
      overflow-y: scroll;
      height: calc(100% - 100px);
    }
    .TradeDate {
      display: block;
    }
    .TbdCheckboxSet {
      display: inline-block;
      width: 20%;
    }
    .p-calendar {
      display: grid !important;
    }
  
    .calendar {
      padding: 10px;
      position: relative;
      border: 1px solid lightgray;
    }
    .dollar_css{
      position: absolute;
      top: 34px;
      left: 20px;
    }
    .dollar_div{
      position: relative;
    }
    .offering_create_input{
      // padding-left: 20px !important;
      width: 100%;
    }
    .dollar_last_closing_css{
      position: absolute;
      top: 34px;
      left: 20px;
    }
    .last_closing_btn_div{
      padding-top: 30px;
    }
  .errorvalidation{
    font-size: 15px;
    color: #FF0000;
    font-weight: bold;
    margin-left: 17px;
  }
  .errorvalidation1{
    font-size: 15px;
    color: #FF0000;
    font-weight: bold;
  }
  .brouche_url_css{
    margin-left: 5px;
  }
}
.ModalHeader{
  height: 30px;
}
.float_right{
  float:right;
}
.closeBtn span{
  font-size: 50px;
  color: #689F38;
}
.closeBtn:focus {
  outline: none !important;
}
.OfferingUpdateConfirmModalBody{
  .confirmationmodalHeader{
    font-size: 20px !important;
    color: #0A5074;
    cursor: pointer;
    font-weight: bold;
  }
  height: calc(100vh - 100px);
  text-align: center;
  font-style: normal !important;
  line-height: 20px !important;
  font-size: 18px !important;
  overflow: scroll;
  .sidelabel{
    padding: 3px !important;
    font-size: 17px !important;
    color: #0A5074;
    font-weight: bold;
  }
  .rightlabel{
    text-align: left !important;
    padding: 5px !important;
  }
  .sendNotificationBtn{
    background-color: #689F38 !important;
    border-color: #689F38 !important;
    color: #fff !important;
    font-size: 18px;
    width: max-content !important;
    font-weight: bold;
  }
  .FollowerTablefooter{
    text-align: right;
    margin: 20px;
  }
}
.submit_offering_update {
  background-color: #689F38 !important;
  border-color: #689F38 !important;
  color: #fff !important;
  font-size: 18px;
}
.brochure_url_groupbtn {
  display: flex;
  .brochure_url_groupbtn_inner {
    display: block !important;
  }
  .btn-brochure_url_groupbtn, .btn-brochure_url_groupbtn:hover{
    margin-top: 0px !important;
    background: #689F38 !important;
    color: #fff;
  }
  .brochure_url_groupbtn-active, .brochure_url_groupbtn-active:hover{
    background: #65a214 !important;
  }
}
.btn-investment, .btn-investment:hover{
  // margin-top: 0px !important;
  // background: #689F38 !important;
  // color: #fff !important;
}
input.update-input {
    width: 100%;
    // height: 48px;
    margin-top: 15px;
    border-radius: 4px;
    border-color: #bebebe;
    border-style: solid;
    padding: 6px;
}
.p-multiselect-label {
  margin-bottom: 0px;
}
.p-multiselect {
  height: auto !important;
  border-radius: 4px !important;
  min-height: 40px;
}
.dsp_file_disable {
  pointer-events: none;
  background-color: #e8e8e8 !important;
}
.dsp_file_disable_text {
  pointer-events: none;
  border: 1px solid #e8e8e8 !important;
  color: #e8e8e8 !important;
}