
.orders_content{
    margin: auto;
    height: 100%;
    background-color: #fff;
    // overflow: hidden;
    padding: 1rem;

    max-width:100%;
    // overflow-x:auto;
    // border:1px solid black;
    // background-color:lightgray;
    .orders-body-content {
        height: calc(100vh - 272px);
        overflow: hidden;
        overflow-y: auto;
    }
    .broker-orders-body-content {
        height: 100%;
    }
    
    .nodatafound {
        font-weight: 700;
        color: #245776;
        font-size: 20px;
        line-height: 24px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%;
    }
    .close-icon-for-search {
        top: 0px !important;
    }
    .Headingname {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 24px;
        color: #8DC73F;
        font-weight: 600;
        // margin-left: 30px;
        // margin-top: -10px;
    }
    .orderpage-subheading {
        padding-top: 10px;

    }
    .red_color {
        color: red;
    }
    .totalAmountOfferingDetail {
        font-weight: 700;
        color: #245776;
        font-size: 18px;
        line-height: 24px;
        p {
            margin-bottom: 0px;
        }
    }
    .offeringOrderListScroll {
        overflow: hidden;
        overflow-y: scroll;
        // height: calc(100vh - 280px);
    }
    .offeringGlobalSearch {
        display: inline-flex;
        width: 100%;
        .filterbyStatus {
            padding-top: 5px;
            font-weight: bold !important;
            color: #245776;
            font-size: 18px;
            margin-right: 10px;
        }
        .multi-select {
            position: relative;
            background-color: #fff;
            border-radius: 4px;
            width: 200px;
            outline: none;
            display: inline-block;
            -webkit-user-select: none;
            -moz-user-select: none;
            -khtml-user-select: none;
            -ms-user-select: none;
            /* height: 28px; */
        }
        .filterbyContent {
            padding-top: 5px;
            font-weight: bold !important;
            color: #245776;
            font-size: 18px;
            margin-left: 20px;
            margin-right: 10px;
        }
        input[type=text] {
            // height: 40px;
            // border-radius: 50px;
            // border: 2px solid #8DC73F;
            width: 200px;
        }
    }
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    .order-count {
        color: #689F38;
        cursor: pointer;
        font-weight: bold;
    }
    .green_color {
        color: #689F38;
    }
    .orders-box-conent {
        overflow: hidden;
        height: calc(100vh - 260px);
        overflow-y: auto;
    }
    .noDataFound {
        padding-top: 25px;
        font-weight: bold !important;
        color: #245776;
        font-size: 18px;
        margin-left: 10px;
        text-align: center;
    }
    .order-broker-name {
        width: fit-content;
        padding: 10px 10px 10px 0px;
        cursor: pointer;
        color: #8DC73F;
        font-weight: bold;
        display: flex;
    }
    .broker-orderlist-table {
        .rgt-wrapper {
            height: calc(100vh - 350px);    
        }
        .rgt-cell-header-inner {
            font-weight: bold;
        }
    }
    .orderlist-table {
        .rgt-wrapper {
            height: calc(100vh - 200px);    
        }
        .rgt-cell-header-inner {
            font-weight: bold;
        }
    }
}
.order-info-titles-email {
    color: #8DC73F;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    font-size: 15px;
}
.order-info-sub-titles {
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    color: #CACACA;
}
.order-info-sub-titles-value {
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    text-align: right;
    color: #678899;
}

.order-buildout-detail-amountbox {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
}
.notificationModal_bottom_text {
    font-size: 16px;
    margin: 0px;
}
.custom-order-export-modal {
    min-height: 50vh;
}
.export-order-amount-field {
    .MuiInputAdornment-positionStart {
        margin-left: 8px;
        margin-right: 0px;
        height: auto;
    }
}