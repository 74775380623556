
.offering_content{
    margin: auto;
    height: calc(100vh - 80px);
    background-color: #fff;
    overflow: hidden;
    padding: 0.5rem 1rem;
    .nodatafound {
        font-weight: 700;
        color: #245776;
        font-size: 20px;
        line-height: 24px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%;
    }
    
    .offeringcollapse{
        text-align: center;
        border: 1px solid #aba8a8;
        margin-top: 10px !important;
        width: 100%;
        margin: 0px;
        padding: 10px;
        .p-calendar {
            width: 100%;
        }
        .p-calendar .p-datepicker {
            min-width: auto !important;
        }
        .p-datepicker table td {
            padding: 0 !important;
        }
        .p-datepicker table th {
            padding: 0 !important;
        }
        .p-datepicker .p-timepicker {
            padding: 0 !important;
        }
        .p-datepicker .p-timepicker button {
            font-size: 0.75em !important;
        }
        .p-datepicker .p-timepicker span {
            font-size: 1em !important;
        }
    }
    .offeringcollapse .header{
      font-size: 16px;
      color: #000;
    }
    .calenderArea {
        padding-top: 22px;
        margin-left: 16px;
        .p-calendar {
            margin-right: 16px;
        }
    }
    .offeringcollapse  input[type=text]{
      width: 97% !important;
    }
    .react-datepicker-wrapper {
        .react-datepicker__input-container {
            input[type=text]{
                width: 100% !important;
            }
        }
    }
    .offering_head {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 30px;
        color: #8DC73F;
        font-weight: 600;
        margin-top: 15px;
    }
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    .offeringGlobalSearch {
        display: inline-flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        .filterbyContent {
            padding-top: 20px;
            font-weight: bold !important;
            color: #245776;
            font-size: 18px;
            margin-left: 10px;
        }
        .selectField {
            margin-top: 20px;
            border: 1px solid rgba(0,0,0,0.32) !important;
            // border: 2px solid #8DC73F !important;
            width: 150px;
            margin-left: 20px;
            outline: none;
            padding: 6px;
            border-radius: 4px;
        }
        .btn-newoffering {
            border: none;
            font-size: 18px;
            cursor: pointer;
            text-shadow: none;
            padding: 10px;
            text-align: center;
            line-height: 15px;
            display: inline-block;
            border-radius: 4px;
            color: #fff;
            background-color: #8DC73F;
            border-color: #8DC73F;
            width: max-content;
            margin-top: 20px;
        }
        
        .close-icon-for-search {
            top: 0px !important;
        }
    }
    .offering-box-conent {
        overflow: hidden;
        // height: calc(100vh - 260px);
        height: 100%;
        overflow-y: auto;
    }
    .icon_column {
        width: 50px;
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }
    .offeringInfo span {
        color: #000000;
        font-size: 20px;
        line-height: 24px;
        display: inline !important;
        vertical-align: top;
    }
    .offeringName {
        font-weight: 700;
        color: #245776;
        font-size: 20px;
        line-height: 24px;
        display: inline-block;
        vertical-align: top;
    }
    .offeringcontent .titles {
        /* width: 120px; */
        // display: inline-block;
        vertical-align: top;
        color: #333333;
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
    }
    .offeringcontent .sub_titles {
        color: #999;
        font-weight: 600;
        margin-left: 10px;
    }
    .offering_inline_checkbox{
        margin-top: 20px;
        .checkbox_list_single{
            margin-right: 10px;
        }
        .checkbox_list{
            float: left;
            margin-right: 10px;
        }
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 2em;
        cursor: pointer;
        font-size: 15px;
        font-weight: normal;
        }
    
        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0; top: 0;
        width: 1.5em; height: 1.5em;
        border: 2px solid #ccc;
        background: #fff;
        border-radius: 4px;
        box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
        }
        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
        content: '\2713\0020';
        position: absolute;
        top: .15em; left: .22em;
        font-size: 1.3em;
        line-height: 0.8;
        color: #8DC73F;
        transition: all .2s;
        }
        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
        }
        [type="checkbox"]:checked + label:after {
        opacity: 1;
        transform: scale(1) ;
        }
        /* disabled checkbox */
        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
        box-shadow: none ;
        border-color: #bbb ;
        background-color: #ddd ;
        }
        [type="checkbox"]:disabled:checked + label:after {
        color: #999 ;
        }
        [type="checkbox"]:disabled + label {
        color: #aaa ;
        }
       
        /* hover style just for information */
        label:hover:before {
        border: 2px solid #8DC73F !important;
        }
    }
    .available_checkbox{
        height: 40px !important;
        width: 40px !important;
        // outline: 3px solid blue !important;
    }
    .offeringbuttons {
        background-color: #245776;
        color: #fff;
    }
}
.offering_callbacks_content {
    margin: auto;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    padding: 0.5rem 1rem;
    overflow-y: auto;
    .less-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
    }
    .Headingname {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 24px;
        color: #8DC73F;
        font-weight: 600;
    }
    .offering-callback-body-content {
        height: 100%;
        .rgt-wrapper {
            height: calc(100vh - 300px);    
        }
        .rgt-cell-header-inner {
            font-weight: bold;
        }
        .callback-content span {
            // display: inline-block !important;
            white-space: normal !important;
            font-style: normal !important;
            font-size: 16px !important;
            color: #0A5074 !important;
        }
        .callback-lists {
            padding: 10px;
            border: 1px solid #ddd;
            .callback-lists-name-box{
                .callback-lists-name-plus{
                    margin-right: 10px;
                    font-size: 24px;
                    display: inline-flex;
                    color: #0A5074;
                    cursor: pointer;
                    .callback-lists-name-plus-icon {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    .callback-lists-name {
                        font-size: 18px;
                        padding-top: 5px;
                        padding-left: 10px;
                        font-weight: bold;
                    }
                }
            }
            .callback-lists-callback_items {
                margin-top: 20px;
                .callback-lists-callback_item {
                    margin-bottom: 5px;
                }
            }
        }
    }
    
}
.dsp-list-table {
    .rgt-wrapper {
        height: calc(100vh - 350px);    
    }
    .rgt-cell-header-inner {
        font-weight: bold;
    }
}
.styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
  
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
  
    // Box.
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      background: #bcbcbc;
      margin-top: -1px;
    }
  
    // Box hover
    // &:hover + label:before {
    //   background: #8DC73F;
    // }
    
    // Box focus
    &:focus + label:before {
    //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }
  
    // Box checked
    &:checked + label:before {
      background: #8DC73F;
    }
    
    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
  
    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
  
    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
    //   position: absolute;
    //   left: 5px;
    //   top: 9px;
    //   background: white;
    //   width: 2px;
    //   height: 2px;
    //   box-shadow: 
    //     2px 0 0 white,
    //     4px 0 0 white,
    //     4px -2px 0 white,
    //     4px -4px 0 white,
    //     4px -6px 0 white,
    //     4px -8px 0 white;
    //   transform: rotate(45deg);
    }
  }
  $color1: #bcbcbc;
  $color2: #8DC73F;

  .radio-custom {
    margin: 0.5rem;
    text-align: center;
    input[type="radio"] {
      position: absolute;
      opacity: 0;

      +.radio-label {
        &:before {
          content: '';
          background: $color1;
          border-radius: 100%;
          border: 1px solid darken($color1, 25%);
          display: inline-block;
          width: 1em;
          height: 1em;
          position: relative;
          top: 0.2em;
          margin-right: 1em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }

      &:checked {
        +.radio-label {
          &:before {
            background-color: $color2;
            // box-shadow: inset 0 0 0 4px $color1;
          }
        }
      }

      &:focus {
        +.radio-label {
          &:before {
            outline: none;
            border-color: $color2;
          }
        }
      }

      &:disabled {
        +.radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px $color1;
            border-color: darken($color1, 25%);
            background: darken($color1, 25%);
          }
        }
      }

      +.radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
  
.sendNotificationContainer .labelheader{
    font-size: 16px ;
    font-weight: bold !important;
    color: #8DC73F;
    margin-top: 15px;
    margin-bottom: 19px;
  }
  .sendNotificationContainer .sendMailBtn{
      background-color: #8DC73F !important;
      border-color: #8DC73F !important;
      color: #fff !important;
      font-size: 18px;
      width: max-content !important;
      font-weight: bold;
      width: 90% !important;
      text-align: center;
      margin-top:10px;
  }
  
  .sendNotificationContainer .sendNotifyBtn{
      background-color: #8DC73F !important;
      border-color: #8DC73F !important;
      color: #fff !important;
      font-size: 18px;
      font-weight: bold;
      width: 100% !important;
      text-align: center;
      margin-top: 20px;
      margin-left: 0;
      margin-right: 0;
  }
  .notification_offeringTitle_bold{
    font-weight: bold !important;
    font-size: 16px;
    margin: 0px;
    color:  #000;
}
.send-notify-confirm-modal{
    .send-notify-confirm-modal-title {
        font-size: 17px !important;
        color: #0A5074;
        font-weight: bold;
    }
    .send-notify-confirm-modal-subtitle {
        font-size: 14px !important;
        color: #0A5074;
        font-weight: bold;
    }
    .sendCancelNotifyBtn{
        background-color: #8DC73F !important;
        border-color: #8DC73F !important;
        color: #fff !important;
        font-size: 18px;
        width: 110px;
        font-weight: bold;
        text-align: center;
        margin-top:10px;
    }
    .send-notify-confirm-modal-message{
        height: 200px;
        overflow: hidden;
        overflow-y: auto;
    }
}

.comment-modal-style {
    .styles_closeButton__20ID4{
        z-index: 99;
    }
    .color-of-star-half {
        color: rgb(255, 180, 0);
    }
    .comment-modal-box {
        height: 100%;
        // position: relative;
    }
    .comment-modal-box-header {
        // position: absolute;
        // top: 0;
        width: 100%;
        padding: 15px;
        border-bottom: 1px solid #ddd;
        background: #fff;
        z-index: 9;
    }
    .comment-modal-box-header .comment-title {
        font-size: 20px;
        text-align: center;
        font-weight: bold;
    }
    .comment-modal-box-content {
        // min-height: 500px;
        // overflow-y: auto;
        // padding-top: 60px;
        // padding-bottom: 200px;
        padding-left: 10px;
        padding-right: 10px;
        max-height: 600px;
    }
    .comment-loading {
        text-align: center;
        img {
            width: 80px;
        }
    }
    .comment-modal-box-content .comment-list {
        padding: 10px;
        border-top: 1px solid #ddd;
        position: relative;
    }

    .comment-modal-box-content .comment-list-alies {
        font-size: 18px;
    }
    .comment-modal-box-content .comment-list-rating .dv-star-rating i{
        font-size: 22px;
    }
    .comment-modal-box-content .comment-list-rating .dv-star-rating label{
        margin-bottom: 0rem;
        line-height: 22px;
    }
    .comment-modal-box-content .comment-list-rating {
        position: relative;
        margin-top: 10px;
    }
    span {
        font-size: unset;
        color: unset;
        display: unset;
        font-weight: unset;
    }
    .comment-modal-box-content .comment-list-rating .updated-date {
        margin-left: 20px;
        font-size: 12px;
        position: absolute;
        top: 0px;
        color: #999;
    }
    .comment-modal-box-content .comment-list-comment {
        /* text-align: center; */
        word-break: break-all;
    }

    .comment-modal-box-footer {
        position: absolute;   
        bottom: 0;
        width: 100%; 
        padding: 15px;
        border-top: 1px solid #ddd;
        background: #fff;
        z-index: 9;
    }
    .comment-modal-box-footer .comment-footer-rating {
        text-align: center;
    }
    .comment-modal-box-footer .comment-footer-rating .comment-footer-rating-title {
        margin-bottom: 10px;
        color: #5fcc40;
    }
    .comment-modal-box-footer .comment-footer-rating .dv-star-rating i{
        font-size: 48px;
    }
    .comment-modal-box-footer .comment-footer-rating .dv-star-rating label{
        margin-bottom: 0rem;
        line-height: 22px;
    }
    .comment-modal-box-footer .comment-submit-btn {
        text-align: center;
    }
    .comment-modal-box-footer .comment-submit-btn button{
        background: #5fcc40;
        color: #fff;
        margin: auto;
        cursor: pointer;
        padding: .25rem 1rem !important;
        font-size: 24px !important;
    }

}