
.allocation_content{
    margin: auto;
    height: calc(100vh - 80px);
    background-color: #fff;
    overflow: hidden;
    padding: 1rem;
    
    .custom-customer-table  {
        max-width:100%;
        overflow-x:auto;
        // border:1px solid black;
        // background-color:lightgray;
    }
    
    .custom-customer-table  .table-show-allocation {
        background-color:transparent;
        min-width:100%;
    }
    
    .custom-customer-table .table-show-allocation thead,
    .custom-customer-table .table-show-allocation tbody,
    .custom-customer-table .table-show-allocation tfoot {
        display:block;
    }
    
    .custom-customer-table .table-show-allocation thead > tr, .custom-customer-table .table-show-allocation tfoot > tr  {
        // width:calc(100% - 8px);
    }
    
    .custom-customer-table .table-show-allocation tbody {
        max-height:calc(100vh - 465px);
        overflow-x: hidden;
    }
    
    .custom-customer-table .table-show-allocation tr {
        display:flex;
    }
    
    .custom-customer-table .table-show-allocation td, 
    .custom-customer-table .table-show-allocation th {
        overflow:hidden;
        // text-overflow:ellipsis;
        // white-space:nowrap;
        padding:3px 10px;
        width:100%;
        background-color:white;
        border:1px solid lightgray;
        word-break: break-all;
    }
  
    .custom-customer-table .table-show-allocation th:nth-child(1),
    .custom-customer-table .table-show-allocation td:nth-child(1) {
        min-width:85px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(2),
    .custom-customer-table .table-show-allocation td:nth-child(2) {
        min-width:200px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(3),
    .custom-customer-table .table-show-allocation td:nth-child(3) {
        min-width:200px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(4),
    .custom-customer-table .table-show-allocation td:nth-child(4) {
        min-width:200px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(5),
    .custom-customer-table .table-show-allocation td:nth-child(5) {
        min-width:150px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(6),
    .custom-customer-table .table-show-allocation td:nth-child(6) {
        min-width:300px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(7),
    .custom-customer-table .table-show-allocation td:nth-child(7) {
        min-width:250px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(8),
    .custom-customer-table .table-show-allocation td:nth-child(8) {
        min-width:250px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(9),
    .custom-customer-table .table-show-allocation td:nth-child(9) {
        min-width:250px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(10),
    .custom-customer-table .table-show-allocation td:nth-child(10) {
        min-width:250px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(11),
    .custom-customer-table .table-show-allocation td:nth-child(11) {
        min-width:200px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(12),
    .custom-customer-table .table-show-allocation td:nth-child(12) {
        min-width:200px;
    }
    .custom-customer-table .table-show-allocation th:nth-child(13),
    .custom-customer-table .table-show-allocation td:nth-child(13) {
        min-width:200px;
    }
    .custom-customer-table > div {
        display: table-row-group;
    }
    .custom-customer-table > div > div {
        display: table-row-group;
    }
    .nodatafound {
        font-weight: 700;
        color: #245776;
        font-size: 20px;
        line-height: 24px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%;
    }
    .offeringcollapse{
        text-align: center;
        border: 1px solid #aba8a8;
        margin-top: 10px !important;
        width: 100%;
        margin: 0px;
        padding: 10px;
        .p-calendar {
            width: 100%;
        }
        .p-calendar .p-datepicker {
            min-width: auto !important;
        }
        .p-datepicker table td {
            padding: 0 !important;
        }
        .p-datepicker table th {
            padding: 0 !important;
        }
        .p-datepicker .p-timepicker {
            padding: 0 !important;
        }
        .p-datepicker .p-timepicker button {
            font-size: 0.75em !important;
        }
        .p-datepicker .p-timepicker span {
            font-size: 1em !important;
        }
    }
    .offeringcollapse .header{
      font-size: 16px;
      color: #000;
    }
    .offeringcollapse  input[type=text]{
      width: 97% !important;
    }
    .offering_head {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 30px;
        color: #8DC73F;
        font-weight: 600;
        margin-top: 15px;
    }
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    // .table-show-allocation {
    //     height: calc(100vh - 300px);
    //     display: block;
    //     overflow-x: auto;
    //     overflow-y: auto;
    //     white-space: nowrap;
    // }
    .show-allocation-body-content {
        // height: calc(100vh - 280px);
        overflow: hidden;
        overflow-y: auto;
        
    }
    .show-allocation-body-section {
        p {
            margin-bottom: 5px;
        }
    }
    .greater-buying-power {
        width: 250px;
        padding: 6px;
        border: 1px solid #a6a6a6;
        border-radius: 4px;
        color: red;
        position: relative;
        .close-greater-buying-power {
            position: absolute;
            top: 8px;
            right: 10px;
            height: 20px;
            width: 20px;
            text-align: center;
            font-size: 26px;
            line-height: 20px;
            cursor: pointer;
        }
    }
    .offeringGlobalSearch {
        
        align-items: center;
        justify-content: flex-end;
        display: inline-flex;
        width: 100%;
        .calenderBoxCross{
            position: absolute;
            font-size: 20px; 
            top: 4px;
            right: 26px;
            color: black;
            cursor: pointer;
        }
        .calenderBox{
            width: 260px;
            margin-top: 18px;
            .p-calendar {
                width: 100%;
                input {
                    width: 100%;
                    padding: 8px;
                }
            }
        }
        .filterbyContent {
            padding-top: 25px;
            font-weight: bold !important;
            color: #245776;
            font-size: 18px;
            margin-left: 10px;
        }
        .selectField {
            margin-top: 20px;
            border: 2px solid #8DC73F !important;
            width: 250px;
            margin-left: 20px;
            outline: none;
        }
        
        .btn-newoffering {
            border: none;
            font-size: 18px;
            cursor: pointer;
            text-shadow: none;
            padding: 10px;
            text-align: center;
            line-height: 15px;
            display: inline-block;
            border-radius: 4px;
            color: #fff;
            background-color: #8DC73F;
            border-color: #8DC73F;
            width: max-content;
            margin-top: 20px;
        }
    }
    // .offering-box-conent {
    //     overflow: hidden;
    //     height: calc(100vh - 260px);
    //     overflow-y: auto;
    // }
    .icon_column {
        width: 50px;
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }
    .offeringInfo span {
        color: #000000;
        font-size: 20px;
        line-height: 24px;
        display: inline !important;
        vertical-align: top;
    }
    .offeringName {
        font-weight: 700;
        color: #245776;
        font-size: 20px;
        line-height: 24px;
        display: inline-block;
        vertical-align: top;
    }
    .offeringcontent .titles {
        /* width: 120px; */
        // display: inline-block;
        vertical-align: top;
        color: #333333;
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
        .sub_titles {
            color: #999;
            font-weight: 600;
            margin-left: 10px;
        }
    }
    .warrent-dsp-checkbox {
        margin-top: 28px;
    }
    .createwarrant_box {
        border: 1px solid #ddd;
    }
    .broker_box {
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        border-right: 1px solid #ddd;
        
        margin-bottom: 30px;
    }
    .offeringbuttons {
        background-color: #245776;
        color: #fff;
    }
    .headingname-show-allocation {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 24px;
        color: #8DC73F;
        font-weight: 600;
    }
    .show-allocation-title-name {
        text-align: center;
        font-size: 24px;
        color: #083952;
    }
    .dataTableTotal {
        font-style: normal;
        font-weight: bold;
        line-height: 20px;
        font-size: 16px;
        color: #0A5074;
    }
    .update-error {
        color: red !important;
    }
    .ErrorMessage {
        font-style: normal;
        line-height: 20px;
        font-size: 18px;
        color: #FF0000;
    }
    .text_center {
        text-align: center;
    }
    .create-allocation-form {
        .update-label {
            font-size: 14px;
            line-height: 20px;
            color: #678899;
        }
        .p-calendar {
            input {
                width: 100%;
                border-radius: 8px;
                border: 1px solid rgba(0,0,0,0.32);
            }
        }
    }
    .allocation-filters {
        display: flex;
        .filterbyContent {
            padding-top: 7px;
            font-weight: bold !important;
            color: #245776;
            font-size: 18px;
        }
        .selectField {
            border: 2px solid #8DC73F !important;
            width: 150px;
            margin-left: 20px;
            margin-right: 20px;
            outline: none;
            outline-color: red !important;
        }
        button {
            margin-left: 20px;
        }
    }
    
    @media screen and (max-width: 900px) and (min-width: 300px) {
        overflow-y: auto;

        .custom-customer-table .table-show-allocation tbody {
            max-height: 300px !important;
            overflow-x: hidden;
        }
    }
    .close-icon-for-search {
        top: 0px !important;
    }
    .rgt-wrapper {
        height: calc(100vh - 350px);    
    }
    .rgt-cell-header-inner {
        font-weight: bold;
    }
}
.allocation_content_pricing {
    margin: auto;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    padding: 1rem;
    
    .headingname-show-allocation {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 24px;
        color: #8DC73F;
        font-weight: 600;
    }
}
.allocation_content_show_allocation {
    margin: auto;
    background-color: #fff;
    overflow: hidden;
    padding: 1rem;
    .offeringcollapse{
        text-align: center;
        border: 1px solid #aba8a8;
        margin-top: 10px !important;
        width: 100%;
        margin: 0px;
        padding: 10px;
        .p-calendar {
            width: 100%;
        }
        .p-calendar .p-datepicker {
            min-width: auto !important;
        }
        .p-datepicker table td {
            padding: 0 !important;
        }
        .p-datepicker table th {
            padding: 0 !important;
        }
        .p-datepicker .p-timepicker {
            padding: 0 !important;
        }
        .p-datepicker .p-timepicker button {
            font-size: 0.75em !important;
        }
        .p-datepicker .p-timepicker span {
            font-size: 1em !important;
        }
    }
    .offeringcollapse .header{
      font-size: 16px;
      color: #000;
    }
    .offeringcollapse  input[type=text]{
      width: 97% !important;
    }
    .offering_head {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 30px;
        color: #8DC73F;
        font-weight: 600;
        margin-top: 15px;
    }
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    // .table-show-allocation {
    //     height: calc(100vh - 300px);
    //     display: block;
    //     overflow-x: auto;
    //     overflow-y: auto;
    //     white-space: nowrap;
    // }
    .show-allocation-body-content {
        // height: calc(100vh - 280px);
        overflow: hidden;
        overflow-y: auto;
        
    }
    .show-allocation-body-section {
        p {
            margin-bottom: 5px;
        }
    }
    .greater-buying-power {
        width: 250px;
        padding: 6px;
        border: 1px solid #a6a6a6;
        border-radius: 4px;
        color: red;
        position: relative;
        .close-greater-buying-power {
            position: absolute;
            top: 8px;
            right: 10px;
            height: 20px;
            width: 20px;
            text-align: center;
            font-size: 26px;
            line-height: 20px;
            cursor: pointer;
        }
    }
    .offeringGlobalSearch {
        
        align-items: center;
        justify-content: flex-end;
        display: inline-flex;
        width: 100%;
        .calenderBoxCross{
            position: absolute;
            font-size: 20px; 
            top: 4px;
            right: 26px;
            color: black;
            cursor: pointer;
        }
        .calenderBox{
            width: 260px;
            margin-top: 18px;
            .p-calendar {
                width: 100%;
                input {
                    width: 100%;
                    padding: 8px;
                }
            }
        }
        .filterbyContent {
            padding-top: 25px;
            font-weight: bold !important;
            color: #245776;
            font-size: 18px;
            margin-left: 10px;
        }
        .selectField {
            margin-top: 20px;
            border: 2px solid #8DC73F !important;
            width: 250px;
            margin-left: 20px;
            outline: none;
        }
        
        .filterbyContent {
            padding-top: 25px;
            font-weight: bold !important;
            color: #245776;
            font-size: 18px;
            margin-left: 10px;
        }
        .btn-newoffering {
            border: none;
            font-size: 18px;
            cursor: pointer;
            text-shadow: none;
            padding: 10px;
            text-align: center;
            line-height: 15px;
            display: inline-block;
            border-radius: 4px;
            color: #fff;
            background-color: #8DC73F;
            border-color: #8DC73F;
            width: max-content;
            margin-top: 20px;
        }
    }
    // .offering-box-conent {
    //     overflow: hidden;
    //     height: calc(100vh - 260px);
    //     overflow-y: auto;
    // }
    .icon_column {
        width: 50px;
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }
    .offeringInfo span {
        color: #000000;
        font-size: 20px;
        line-height: 24px;
        display: inline !important;
        vertical-align: top;
    }
    .offeringName {
        font-weight: 700;
        color: #245776;
        font-size: 20px;
        line-height: 24px;
        display: inline-block;
        vertical-align: top;
    }
    .offeringcontent .titles {
        /* width: 120px; */
        // display: inline-block;
        vertical-align: top;
        color: #333333;
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
        .sub_titles {
            color: #999;
            font-weight: 600;
            margin-left: 10px;
        }
    }
    .warrent-dsp-checkbox {
        margin-top: 28px;
    }
    .offeringbuttons {
        background-color: #245776;
        color: #fff;
    }
    .headingname-show-allocation {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 24px;
        color: #8DC73F;
        font-weight: 600;
    }
    .show-allocation-title-name {
        text-align: center;
        font-size: 24px;
        color: #083952;
    }
    .dataTableTotal {
        font-style: normal;
        font-weight: bold;
        line-height: 20px;
        font-size: 16px;
        color: #0A5074;
    }
    .update-error {
        color: red !important;
    }
    .ErrorMessage {
        font-style: normal;
        line-height: 20px;
        font-size: 18px;
        color: #FF0000;
    }
    .text_center {
        text-align: center;
    }
    .create-allocation-form {
        .update-label {
            font-size: 14px;
            line-height: 20px;
            color: #678899;
        }
        .p-calendar {
            input {
                width: 100%;
                border-radius: 8px;
                border: 1px solid rgba(0,0,0,0.32);
            }
        }
    }
    .allocation-filters {
        display: flex;
        .filterbyContent {
            padding-top: 7px;
            font-weight: bold !important;
            color: #245776;
            font-size: 18px;
        }
        .selectField {
            border: 2px solid #8DC73F !important;
            width: 150px;
            margin-left: 20px;
            margin-right: 20px;
            outline: none;
            outline-color: red !important;
        }
        button {
            margin-left: 20px;
        }
    }
    
    @media screen and (max-width: 900px) and (min-width: 300px) {
        overflow-y: auto;

        .custom-customer-table .table-show-allocation tbody {
            max-height: 300px !important;
            overflow-x: hidden;
        }
    }
    .close-icon-for-search {
        top: 0px !important;
    }
    .rgt-wrapper {
        height: calc(100vh - 350px);    
    }
    .rgt-cell-header-inner {
        font-weight: bold;
    }
}
.sendToTs_content2{
    // height: 650px;
    // overflow: scroll;
    // width: 100%;
    .react-tabs__tab-list {
        display: block;
        overflow-y: hidden;
        overflow-x: auto;
        width: 100%;
        white-space: nowrap;
        margin-bottom: 0;
        border-bottom: none;
        .react-tabs__tab{
            background: #ccc;
            border-color: #ccc;
            color: #000;
            border-radius: 0;
            padding: 10px;
            margin-right: 10px;
        }
        .react-tabs__tab--selected{
            background: #8DC73F !important;
            border-color: #8DC73F !important;
            color: #fff !important;
            border-radius: 0;
            padding: 10px;
        }
    }
}
.sendToTs_wirePrice{
    height: 550px;
    overflow: scroll;
    width: 100%;
}
.sendToTs_wirePrice_content_inner{
    height: calc(100vh - 330px);
    overflow: scroll;
    width: 100%;
}
.brokerDealer_checkbox{
    margin-top: 20px;
    .checkbox_list{
        margin-right: 10px;
        margin-bottom: 15px;
    }
    label{
        background: transparent;
        padding: 0;
    }
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2em;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    font-weight: normal;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 1.5em; height: 1.5em;
    border: 2px solid #ccc;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
    }
    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
    content: '\2713\0020';
    position: absolute;
    top: .15em; left: .22em;
    font-size: 1.3em;
    line-height: 0.8;
    color: #8DC73F;
    transition: all .2s;
    }
    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
    }
    [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1) ;
    }
    /* disabled checkbox */
    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none ;
    border-color: #bbb ;
    background-color: #ddd ;
    }
    [type="checkbox"]:disabled:checked + label:after {
    color: #999 ;
    }
    [type="checkbox"]:disabled + label {
    color: #aaa ;
    }
   
    /* hover style just for information */
    label:hover:before {
    border: 2px solid #8DC73F !important;
    }
}
.tbl-sendToTS td {
    padding: 5px;
    border: 1px solid #999;
    font-size: 18px;
    text-shadow: none;
    text-align: center;
    line-height: 15px;
}
.run-allocation-combo {
    padding: 24px 0px 0px !important;
    margin-right: -8px !important;
    text-align: center !important;
    p {
        line-height: 1.5 !important;
    }
}
.runAllocationmodal {
    .runallocation-dollar-sign {
        p {
            line-height: 1.4;
        }
    }
}
.selection-bold {
    font-weight: bold;
}
.round-lot-switch {
    .MuiSwitch-track {
        opacity: 0.7;
        background-color: #689F38;
    }
    .MuiSwitch-thumb {
        background-color: #689F38;
    }
}