.create_offering_content_wrapper {
    overflow-y: auto;
    background: #fff;
    padding-top: 10px;
    .editprofile_content{
      margin: auto;
  }
  .css-1wa3eu0-placeholder {
    width: 100%;
  }
  .MuiInputAdornment-root p {
    // min-width: 5px;
    text-align: center;
    line-height: 0px;
  }
  .MuiSelect-selectMenu {
    padding: 10px;
  }
  .MuiInputAdornment-positionStart {
    margin-right: 0;
  }
  .createprofile_heading{
    text-align: center;
    height: 50px;
    font-size: 27px;
    color: #083952 !important;
    margin-bottom: 15px;
    position: relative;
  }
  .createprofile_back_icon {
    width: 100%;
    max-width: 35px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
  .createprofile_go_back{
    color: #888888;
    position: absolute;
    left: 31px;
    top: 49%;
    font-size: 16px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  @media (max-width: 575px) {
    .createprofile_back_icon,
    .createprofile_go_back {
        position: static;
        transform: initial;
        display: inline-block;
        vertical-align: middle;
    }
    .createprofile_back_icon_text {
        text-align: left;
        margin-top: 10px;
    }
    .createprofile_heading {
        line-height: 1;
        height: auto;
    }
  }
  
  .update_profile .btn{
      color: #fff;
      background-color: #5fcc40 !important;
      border-color: #5fcc40 !important;
      width: 100%;
      margin-top: 10px;
      font-size: 18px;
  }
  .btn-primary:focus{
      box-shadow: none !important;
      outline: none;
  }
  .user_details .form-control:focus{
      box-shadow: none !important;
  }
  .edit_profile_form_fields_wrapper {
      background: #fff;
      padding: 15px;
      border-radius: 4px;
  }
  .edit_profile_form_fields_wrapper .form-group {
      margin-bottom: 1rem !important;
  }
  .edit_profile_form_fields_wrapper .form-group,
  .edit_profile_form_fields_wrapper button {
      margin-top: 1rem;
  }
  .edit_profile_form_fields_wrapper .form-control {
      border: 1px solid lightgray;
  }
  
  
  .advisor-checkbox {
      position: absolute;
      opacity: 0;
      z-index: 999;
    }
    .advisor-checkbox + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      margin-bottom: 0px;
      margin-top: 10px;
    }
    .advisor-checkbox + label:before {
      content: '';
      /* margin-right: 10px; */
      /* margin-top: 4px !important; */
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      border: 1px solid #a6a6a6;
      background-color: #ffffff;
      border-radius: 4px;
      margin-right: 20px;
    }
    .advisor-checkbox:hover + label:before {
      /* background: #689F38; */
      border: 1px solid #000;
    }
    // .advisor-checkbox:focus + label:before {
    //   /* box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12); */
    // }
    .advisor-checkbox:checked + label:before {
      background: #689F38;
    }
    .advisor-checkbox:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
    .advisor-checkbox:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
    .advisor-checkbox:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 11px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }
    .error-message{
        color: red;
    }
    .brochure_url_radiobox {
      display: flex;
      margin-top: 10px;
    }
    .brochure_url_radiobox_btn {
      margin-right: 20px;
    }
    .update-label{
      font-size: 14px;
      line-height: 2px;
      color: #678899;
    }
    .update-label-checkbox{
      font-size: 14px;
      line-height: 2px;
      color: #333333;
    }
    .create_offering{
      overflow-y: scroll;
      height: calc(100% - 100px);
    }
    .TradeDate {
      display: block;
    }
    .TbdCheckboxSet {
      display: inline-block;
      width: 20%;
    }
    .p-calendar {
      display: grid !important;
    }
  
    .calendar {
      padding: 10px;
      position: relative;
      border: 1px solid lightgray;
    }
    .dollar_css{
      position: absolute;
      top: 34px;
      left: 20px;
    }
    .dollar_css_min_max{
      position: absolute;
      top: 34px;
      left: 5px;
    }
    .dollar_div{
      position: relative;
    }
    .offering_create_input{
      // padding-left: 20px !important;
      width: 100%;
    }
    .dollar_last_closing_css{
      position: absolute;
      top: 34px;
      left: 20px;
    }
    .last_closing_btn_div{
      padding-top: 30px;
    }
  .errorvalidation{
    font-size: 15px;
    color: #FF0000;
    font-weight: bold;
    margin-left: 17px;
  }
  .errorvalidation1{
    font-size: 15px;
    color: #FF0000;
    font-weight: bold;
  }
  .brouche_url_css{
    margin-left: 5px;
  }
}
.ModalHeader{
  height: 30px;
}
.float_right{
  float:right;
}
.closeBtn span{
  font-size: 50px;
  color: #689F38;
}
.closeBtn:focus {
  outline: none !important;
}
.sidelabel{
  padding: 3px !important;
  font-size: 17px !important;
  color: #0A5074;
  font-weight: bold;
}
.rightlabel{
  text-align: left !important;
  padding: 5px !important;
}
// .OfferingConfirmModalBody{
//   .confirmationmodalHeader{
//     font-size: 20px !important;
//     color: #0A5074;
//     cursor: pointer;
//     font-weight: bold;
//   }
//   min-height: 500px;
//   max-height: 100%;
//   text-align: center;
//   font-style: normal !important;
//   line-height: 20px !important;
//   font-size: 18px !important;
//   overflow: scroll;
//   .sidelabel{
//     padding: 3px !important;
//     font-size: 17px !important;
//     color: #0A5074;
//     font-weight: bold;
//   }
//   .rightlabel{
//     text-align: left !important;
//     padding: 5px !important;
//   }
//   .sendNotificationBtn{
//     background-color: #689F38 !important;
//     border-color: #689F38 !important;
//     color: #fff !important;
//     font-size: 18px;
//     width: max-content !important;
//     font-weight: bold;
//   }
//   .FollowerTablefooter{
//     text-align: right;
//     margin: 20px;
//   }
// }
.file-upload-boxes {
  #file-upload-attestation {
      position: absolute;
      left: -9999px;
  }
  #file-upload-faq {
      position: absolute;
      left: -9999px;
  }
  #file-upload-additional {
    position: absolute;
    left: -9999px;
  }
  label[for="file-upload-attestation"]{
    padding:0.5em;  
    display:inline-block;
    background-color: #689F38;
    color: #ffffff;
    cursor:pointer;
    height: 40px;
    &:hover{background-color:#689F38}
  }
  label[for="file-upload-faq"]{
      padding:0.5em;  
      display:inline-block;
      background-color: #689F38;
      color: #ffffff;
      cursor:pointer;
      height: 40px;
      &:hover{background-color:#689F38}
  }
  label[for="file-upload-additional"]{
    padding:0.5em;  
    display:inline-block;
    background-color: #689F38;
    color: #ffffff;
    cursor:pointer;
    height: 40px;
    &:hover{background-color:#689F38}
}
  #filename{
    padding:0.48em;
    float:left;
    width:200px;
    white-space: nowrap;
    overflow:hidden;
    background-color:#fff;
    border: 1px solid #689F38;
    font-size: 14px;
    color: #000;
    height: 40px;
  }
  .dsp_file_disable {
      pointer-events: none;
      background-color: #e8e8e8 !important;
  }
  .dsp_file_disable_text {
      pointer-events: none;
      border: 1px solid #e8e8e8 !important;
      color: #e8e8e8 !important;
  }
}
.offering_form_submit {
  // background: #689F38 !important;
  // color: #fff !important;
  margin: 0 20px;
  min-width: 150px;
}
.offering_form_reset {
  // background: #ffffff !important;
  // color: #689F38 !important;
  // border: 1px solid #689F38 !important;
  margin: 0 20px;
  min-width: 150px;
}
.brochure_url_groupbtn {
  display: flex;
  .brochure_url_groupbtn_inner {
    display: block !important;
  }
  .btn-brochure_url_groupbtn, .btn-brochure_url_groupbtn:hover{
    margin-top: 0px !important;
    background: #689F38 !important;
    color: #fff;
  }
  .brochure_url_groupbtn-active, .brochure_url_groupbtn-active:hover{
    background: #65a214 !important;
  }
}
.popover-table {
  min-width: 180px;
  position: absolute;
  top: 40px;
  left: -35px;
  padding: 10px;
  background: #fff;
  z-index: 99;
  border: 1px solid #ddd;
  text-align: center;
  button {
    width: 50px;
    height: 30px;
    padding: 5px;
    border: none;
  }
}
.investmentTable {
  
  th, td {
    border: 1px solid #dee2e6;
  }
  td {
    padding: 0px !important;
  }
}
.investment-header-modal {
  position: relative;
  .investment-ob-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  .close-btn-investment {
    position: absolute;
    border: none;
    background: transparent;
    font-size: 30px;
    margin-top: -15px;
    right: 0;
    top: 0;
  }
  .close-btn-investment:focus { 
    outline: 0px;
  }
}
.investmentTable-box {
  padding-top: 30px;
}
.checked-after-complete {
  // font-size: 18px;
  // margin-top: -4px;
  // padding-left: 20px;
  // position: absolute;
  // right: 5px;
  // color: green;
  padding-left: 10px;
}

.reviewmodalheading {
  margin: 0;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.reviewmodalclosebtn {
  top: 8px;
  color: #9e9e9e !important;
  right: 8px;
  position: absolute !important;
}
.reviewmodaltitle {
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  text-align: center;
}